export function removeFalsyValuesFromPayload<T extends Object>(
  payload: T,
  additionalFalsyValues: string[] = []
): T {
  // clone query
  const _payload = { ...payload }
  // Remove falsy values and '*' from payload
  for (const key of Object.keys(_payload) as (keyof T)[]) {
    if (
      _payload[key] === undefined ||
      _payload[key] === null ||
      additionalFalsyValues.includes(String(_payload[key]))
    ) {
      delete _payload[key]
    }
  }
  return _payload
}

export function getAdminBasePath(userRole: 'ADMIN' | 'HR') {
  return userRole === 'ADMIN' ? '/admin' : '/hradmin'
}

export function getStrapiDefaultFilters(environmentName: string, endpoint: string, region: string) {
  const query: Record<string, any> = {}
  if (environmentName === 'prod' && endpoint.includes('articles')) {
    query['filters[PublishToProduction][$eq]'] = true
  }

  if (region === 'HK' || region === 'MY' || region === 'SG' || region === 'GLOBAL') {
    query['filters[EnabledRegions][ISO]'] = region
  } else {
    throw new Error('Unsupported region')
  }

  return query
}

export function HTMLToText(html: string, multiline = true) {
  html = html.replace(/<\/p>/gi, '\n') // Replace closing p tags with line breaks
  html = html.replace(/<h\d>/gi, '\n') // Replace opening h tags with line breaks
  html = html.replace(/<[^>]+>/gi, '') // Remove all other HTML tags
  return multiline ? html : html.replace(/\n/g, ' ')
}

export function roundFloat(num: number, decimalPlaces = 0) {
  // if num is Nan, return 0
  if (isNaN(num)) return 0
  const p = Math.pow(10, decimalPlaces)
  const n = num * p * (1 + Number.EPSILON)
  return Math.round(n) / p
}

export function getDemoNumber(
  originalNumber: number | undefined,
  demo: boolean | undefined,
  max: number
) {
  // if originalNumber is 0 return a random integer between 1 to the max
  if (!demo) return originalNumber
  // eslint-disable-next-line security-node/detect-insecure-randomness
  if (!originalNumber) return Math.floor(Math.random() * max) + 1
  return originalNumber
}
